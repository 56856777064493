<template>
  <div class="navbar-container main-menu-content">
<!--    <horizontal-nav-menu-items :items="navMenuItems" />-->
    <horizontal-nav-menu-items v-if="customNavMenuItems.length > 0" :items="customNavMenuItems" />
    <horizontal-nav-menu-items v-else-if="loading === false" :items="navMenuItems" />
<!--    {{ customNavMenuItems }}-->
  </div>
</template>

<script>
import navMenuItems from '@/navigation/horizontal'
import HorizontalNavMenuItems from './components/horizontal-nav-menu-items/HorizontalNavMenuItems.vue'

import { createNamespacedHelpers } from 'vuex'
import { FETCH_GAME_SET_MENU } from '@/store/settings/action'

const settingsStore = createNamespacedHelpers('settingsStore')

export default {
  components: {
    HorizontalNavMenuItems,
  },
  setup() {
    return {
      navMenuItems,
    }
  },
  data() {
    return {
      customNavMenuItems: [],
      loading: true,
    }
  },
  computed: {
    ...settingsStore.mapGetters({
      fetchGameSetMenu: 'fetchGameSetMenu',
    }),
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    ...settingsStore.mapActions({
      $fetchGameSetMenu: FETCH_GAME_SET_MENU,
    }),
    async fetchData() {
      const mySite = this.$site
      await this.$fetchGameSetMenu({
        site: mySite,
      })
      this.customNavMenuItems = navMenuItems
        .map(item => {
          const gameSetMenu = this.fetchGameSetMenu.find(menu => menu.name === item.title)
          if (gameSetMenu) {
            if (gameSetMenu.menuUseYn === 'N') {
              return null
            }
            item.title = gameSetMenu.nameCustom
          }
          return item
        })
        .filter(item => item !== null)
      this.loading = false
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/horizontal-menu.scss";
</style>
